export default {
buildTime: "2024-03-23T05:12:06Z",
commit: "28e9a31b86cfa80a68eee2927c66edb8e9c5ea25",
npmInfo:
{
  mashlib: '1.8.9',
  npm: '8.19.4',
  node: '18.17.1',
  acorn: '8.8.2',
  ada: '2.5.0',
  ares: '1.19.1',
  brotli: '1.0.9',
  cldr: '43.0',
  icu: '73.1',
  llhttp: '6.0.11',
  modules: '108',
  napi: '9',
  nghttp2: '1.52.0',
  nghttp3: '0.7.0',
  ngtcp2: '0.8.1',
  openssl: '3.0.10+quic',
  simdutf: '3.2.12',
  tz: '2023c',
  undici: '5.22.1',
  unicode: '15.0',
  uv: '1.44.2',
  uvwasi: '0.0.18',
  v8: '10.2.154.26-node.26',
  zlib: '1.2.13.1-motley'
}
};
